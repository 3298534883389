import {getProductsPriceInfo} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {RootStore} from '../../../stores/RootStore';
import {SPECS} from '../../../../../specs';
import {GqlProduct} from '../../../../../types/type';

const getSubscriptionPricing = (
  rootStore: RootStore,
): {
  formattedActualPrice?: string;
  frequency?: string;
  interval?: number;
} => {
  const {formattedPrice, interval, frequency} = rootStore.$state.selectedSubscriptionPlan!;
  return {
    formattedActualPrice: formattedPrice,
    interval,
    frequency,
  };
};

const getProductPricing = (
  rootStore: RootStore,
): {
  formattedActualPrice: string;
  formattedOriginalPrice?: string;
} => {
  const {hasDiscount, selectionIds, selectedVariant} = rootStore.$state;
  const product = rootStore.$state.product as GqlProduct;
  const productWithVariant = selectedVariant ? ({...product, ...selectedVariant} as GqlProduct) : product;
  const priceInfo = getProductsPriceInfo({
    product: product as GqlProduct,
    variantSelectionIds: selectionIds,
    isRemoveModifiersSelectionIdsFromUserSelections: rootStore.isExperimentEnabled(SPECS.RemoveModifiersFromUserInput),
  });
  const formattedActualPrice = hasDiscount
    ? formattedPrimaryPrice(
        productWithVariant,
        rootStore.isExperimentEnabled(SPECS.ShouldCheckDiscountInVariantLevel)
          ? rootStore.$state.selectedVariant
          : undefined,
      )
    : priceInfo.formattedPrice;
  const formattedOriginalPrice = hasDiscount
    ? formattedSecondaryPrice(
        productWithVariant,
        rootStore.isExperimentEnabled(SPECS.ShouldCheckDiscountInVariantLevel)
          ? rootStore.$state.selectedVariant
          : undefined,
      )
    : undefined;

  return {
    formattedActualPrice,
    formattedOriginalPrice,
  };
};

const getFormattedPricePerUnit = (rootStore: RootStore) => {
  const {selectedSubscriptionPlan, selectedVariant, product} = rootStore.$state;
  const useProductLevelPricePerUnit = rootStore.isExperimentEnabled(SPECS.PPOBShouldUseProductLevelPricePerUnit);
  if (useProductLevelPricePerUnit) {
    return product.formattedPricePerUnit;
  } else {
    const ref = selectedSubscriptionPlan || selectedVariant || product;
    return ref.formattedPricePerUnit;
  }
};

export const getPriceFields = (
  rootStore: RootStore,
): {
  formattedActualPrice?: string;
  formattedOriginalPrice?: string;
  formattedPricePerUnit?: string | null;
  formattedOneTimePurchasePrice: string;
  frequency?: string;
  discountTitle?: string;
  interval?: number;
} => {
  const {$state} = rootStore;
  const {selectedSubscriptionPlan} = $state;
  const productPricing = getProductPricing(rootStore);
  const formattedPricePerUnit = getFormattedPricePerUnit(rootStore);
  return {
    ...(selectedSubscriptionPlan ? getSubscriptionPricing(rootStore) : productPricing),
    formattedOneTimePurchasePrice: productPricing.formattedActualPrice,
    formattedPricePerUnit,
  };
};

export const getPriceFieldsOld = (
  rootStore: RootStore,
): {
  formattedActualPrice?: string;
  formattedOriginalPrice?: string;
  formattedPricePerUnit?: string | null;
  formattedOneTimePurchasePrice: string;
  frequency?: string;
  discountTitle?: string;
  interval?: number;
} => {
  const ShouldUseProductLevelPricePerUnit = rootStore.isExperimentEnabled(SPECS.PPOBShouldUseProductLevelPricePerUnit);
  const {hasDiscount, selectionIds, selectedSubscriptionPlan, selectedVariant} = rootStore.$state;
  const product = rootStore.$state.product as GqlProduct;
  const productWithVariant = selectedVariant ? ({...product, ...selectedVariant} as GqlProduct) : product;
  const productPriceInfo = getProductsPriceInfo({product, variantSelectionIds: selectionIds});
  const priceInfo = !selectedSubscriptionPlan
    ? productPriceInfo
    : {
        ...selectedSubscriptionPlan,
        formattedComparePrice: undefined,
      };
  const formattedActualPriceWithAutomatedDiscounts = hasDiscount
    ? formattedPrimaryPrice(productWithVariant)
    : priceInfo.formattedPrice;

  const formattedOriginalPriceWithAutomatedDiscounts = hasDiscount
    ? formattedSecondaryPrice(productWithVariant)
    : undefined;

  return {
    formattedActualPrice: formattedActualPriceWithAutomatedDiscounts ?? undefined,
    formattedOriginalPrice: formattedOriginalPriceWithAutomatedDiscounts,
    formattedPricePerUnit: ShouldUseProductLevelPricePerUnit
      ? product.formattedPricePerUnit
      : priceInfo.formattedPricePerUnit,
    formattedOneTimePurchasePrice: productPriceInfo.formattedPrice,
    frequency: selectedSubscriptionPlan?.frequency,
    interval: selectedSubscriptionPlan?.interval,
  };
};
