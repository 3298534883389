import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  mapFlowTypeToBiProperties,
  mapStateToProperties,
} from '../../../../utils/bi/mappers';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { getDynamicPricingInfo } from '../../../../utils/dynamicPricing/dynamicPricing';
import { isSlotNoBookFlow } from '../../../../utils/slotAvailability/slotAvailability';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { navigateToBookingsServicePage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import {
  BookingsQueryParams,
  getCurrentPath,
  isBookingFormInstalled as isBookingFormInstalledUtils,
  isTemplateMode,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { Preference } from '../../../../types/types';
import { DO_NOT_CARE_STAFF } from '../../../../constants/constants';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-calendar-catalog-viewer-analytics-adapter';
import { PresetToWidgetNameMap } from '../../../../utils/bi/consts';
import {
  bookingsUoUFlowBookInitialIntentSrc16Evid33,
  bookingsUoUFlowSlotSelectionCompletedSrc16Evid34,
} from '@wix/bi-logger-bookings-data/v2';
import { isCalendarPage } from '../../../../utils/presets';

export type NavigateToNextPage = ({
  selectedSlots,
  isWaitingListFlow,
}: {
  selectedSlots: SlotAvailability[];
  isWaitingListFlow: boolean;
}) => Promise<void>;

export function createNavigateToNextPageAction(
  actionFactoryParams: ActionFactoryParams<calendarstate, CalendarContext="">,
): NavigateToNextPage {
  return async ({ selectedSlots, isWaitingListFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const {
      biLogger,
      activeFeatures,
      flowAPI,
      isDateAndTimeViewMode,
      calendarSelections,
      preset,
    } = context;
    const {
      servicesInView,
      selectedTime,
      rescheduleBookingDetails,
      selectedBookingPreferences,
    } = state;

    const shouldSelectRandomSlot =
      isDateAndTimeViewMode &&
      calendarSelections?.services[0].resources[0] === DO_NOT_CARE_STAFF &&
      servicesInView.length === 1;

    const selectedSlot: SlotAvailability = shouldSelectRandomSlot
      ? selectedSlots[Math.floor(Math.random() * selectedSlots.length)]
      : selectedSlots[0];
    const selectedStaffBookingPreference = selectedBookingPreferences?.find(
      (bookingPreference) => bookingPreference.key === Preference.STAFF_MEMBER,
    );
    if (selectedStaffBookingPreference?.value === DO_NOT_CARE_STAFF) {
      delete selectedSlot.slot?.resource;
    }
    const selectedServicesIds =
      selectedSlot.nestedSlots!.length > 1
        ? selectedSlot.nestedSlots!.map((slot) => slot.serviceId!)
        : [selectedSlot.slot?.serviceId!];
    const selectedServices = servicesInView.filter((service) =>
      selectedServicesIds?.includes(service.id!),
    )!;

    const isTemplate = isTemplateMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });

    const isBookingEnabled = isBookingsEnabled({
      servicesTypes: selectedServices.map((service) => service.type!),
      activeFeatures: activeFeatures!,
      isTemplateMode: isTemplate,
    });

    const isNoBookFlow = isSlotNoBookFlow(selectedSlot);
    const isReschedulingFlow = !!rescheduleBookingDetails;

    const isRegularBookFlow =
      !isNoBookFlow && !isReschedulingFlow && !isWaitingListFlow;

    if (isRegularBookFlow) {
      if (!isCalendarPage(preset)) {
        void biLogger.report(
          bookingsUoUFlowBookInitialIntentSrc16Evid33({
            widget_name: PresetToWidgetNameMap[preset],
            serviceType: selectedServices[0].type!,
            bookingsServiceId: selectedServices[0].id!,
            isEligibleForFlowCompletion: isBookingEnabled,
          }),
        );
      }
      void biLogger.report(
        bookingsUoUFlowSlotSelectionCompletedSrc16Evid34({
          widget_name: PresetToWidgetNameMap[preset],
          serviceType: selectedServices[0].type!,
          bookingsServiceId: selectedServices[0].id!,
          startTime: new Date(selectedSlots[0].slot?.startDate!).getTime(),
          endTime: new Date(selectedSlots[0].slot?.endDate!).getTime(),
          isEligibleForFlowCompletion: isBookingEnabled,
        }),
      );
    }

    if (isBookingEnabled) {
      const isBookingFormInstalled = await isBookingFormInstalledUtils(
        flowAPI.controllerConfig.wixCodeApi,
      );

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList: isWaitingListFlow,
            isBookingFormInstalled,
            isNoBookFlow,
            properties: mapStateToProperties({ state, context }),
          }),
        }),
      );

      if (isNoBookFlow) {
        handleNoBookFlow({
          selectedService: selectedServices[0],
          context,
        });
      } else if (isReschedulingFlow) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingListFlow) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        await handleRegularBookFlow(
          actionFactoryParams,
          selectedSlot,
          selectedServices,
        );
      }
    } else {
      if (flowAPI.environment.isPreview && !isBookingEnabled) {
        setState({
          dialog: {
            type: DialogType.PremiumPreview,
            state: DialogState.IDLE,
          },
        });
      }

      if (flowAPI.environment.isViewer) {
        setState({
          dialog: {
            type: DialogType.PremiumViewer,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<calendarstate, CalendarContext="">) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<calendarstate, CalendarContext="">) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleNoBookFlow = ({
  selectedService,
  context,
}: {
  selectedService: Service;
  context: CalendarContext;
}) => {
  const {
    preset: referral,
    experiments,
    flowAPI: {
      controllerConfig: { wixCodeApi },
    },
  } = context;
  const serviceSlug = selectedService!.mainSlug?.name!;

  navigateToBookingsServicePage(
    wixCodeApi,
    {
      serviceSlug,
      referral,
    },
    experiments.enabled('specs.bookings.useCustomUrlSegmentsForSlug'),
  );
};

const handleRegularBookFlow = async (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  slotAvailability: SlotAvailability,
  selectedServices: Service[],
) => {
  const {
    experiments,
    businessInfo,
    preset,
    isCartEnabled,
    calendarSelections,
    flowAPI: {
      controllerConfig: { wixCodeApi },
    },
    subscriptions: { onNextClicked },
  } = context;
  const [state, setState] = getControllerState();
  const { selectedTimezone, selectedVariantsOptions, serviceVariantsMap } =
    state;

  try {
    const isMultiServiceAppointment = slotAvailability.nestedSlots?.length;

    const serviceSlug = selectedServices[0]!.mainSlug?.name!;
    const continueShoppingUrl =
      isCartEnabled && !isMultiServiceAppointment
        ? `/${getCurrentPath(wixCodeApi).join('/')}?${
            BookingsQueryParams.TIMEZONE
          }=${state.selectedTimezone}`
        : undefined;

    const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
      services: selectedServices,
      businessName: businessInfo?.name || '',
    });

    trackAnalytics({
      wixCodeApi,
      eventId: trackingInfo.eventId as any,
      payload: trackingInfo.payload,
    });

    const dynamicPriceInfo = getDynamicPricingInfo({
      isAssignStaffVariantToSelectionsWithAnyStaffEnabled: experiments.enabled(
        'specs.bookings.assignStaffVariantToSelectionsWithAnyStaff',
      ),
      serviceVariantsMap,
      selectedSlot: slotAvailability,
      servicesSelections: calendarSelections?.services?.length
        ? calendarSelections.services
        : [
            {
              id: selectedServices[0].id!,
              variants: selectedVariantsOptions,
              resources: [slotAvailability.slot?.resource?.id!],
            },
          ],
    });

    setState(
      { calendarStatus: CalendarStatus.NAVIGATING },
      { immediate: true },
    );

    const shouldNavigate =
      !onNextClicked ||
      (await onNextClicked({ service: selectedServices[0], slotAvailability }))
        ?.shouldNavigate;

    if (shouldNavigate) {
      void navigateToBookingsFormPage({
        wixCodeApi,
        platformAPIs: context.flowAPI.controllerConfig.platformAPIs,
        // @ts-expect-error
        slotAvailability,
        serviceSlug,
        dynamicPriceInfo,
        queryParams: {
          [BookingsQueryParams.REFERRAL]: preset,
          [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
          ...(isCartEnabled
            ? {
                [BookingsQueryParams.CONTINUE_SHOPPING_URL]:
                  continueShoppingUrl,
              }
            : {}),
        },
      });
    }
  } catch (e) {
    setState({ calendarStatus: CalendarStatus.IDLE }, { immediate: true });
  }
};
</calendarstate,></calendarstate,></calendarstate,></calendarstate,></void>