import {
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import timetableSettingsParams from '../WeeklyTimetable/settingsParams';
import { LayoutOptions } from '../../types/types';
import { ISettingsParams } from '../BookingCalendar/settingsParams';

type DailyAgendaSettingsOverride = Pick<
  ISettingsParams,
  | 'calendarLayout'
  | 'maxTimeSlotsDisplayedPerDay'
  | 'limitTimeSlotsDisplay'
  | 'noSessionsOffered'
  | 'loadMoreTimeSlots'
>;

type IDailyAgendaSettingsParamsDefinitions = {
  [key in keyof DailyAgendaSettingsOverride]: PartialBy<
    ISettingsParam<dailyagendasettingsoverride[key]>,
    'key'
  >;
};

export const dailyAgendaSettingsParamsDefinitions: IDailyAgendaSettingsParamsDefinitions =
  {
    calendarLayout: {
      getDefaultValue: () => LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER,
    },
    maxTimeSlotsDisplayedPerDay: {
      getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 6),
    },
    limitTimeSlotsDisplay: {
      getDefaultValue: () => true,
      inheritDesktop: true,
    },
    noSessionsOffered: {
      type: SettingsParamType.Text,
      getDefaultValue: ({ t }) =>
        t('app.settings.defaults.slots.no-availability'),
    },
    loadMoreTimeSlots: {
      type: SettingsParamType.Text,
      getDefaultValue: ({ t }) =>
        t('app.settings.defaults.time-picker.daily-agenda.show-all'),
    },
  };

const dailyAgendaSettingsParams =
  createSettingsParams<dailyagendasettingsoverride>(
    dailyAgendaSettingsParamsDefinitions,
  );

export default {
  ...timetableSettingsParams,
  ...dailyAgendaSettingsParams,
};
</dailyagendasettingsoverride></dailyagendasettingsoverride[key]>