import {RootStore} from '../../stores/RootStore';
import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {WidgetEventHandler} from './types';
import {createCheckout} from '../createCheckout';
import {publishInitiateCheckoutTrackEvent} from '../product-service/publishInitiateCheckoutTrackEvent';
import {interruptAction} from './interruptAction';
import {SPECS} from '../../../../specs';

export const handleBuyNowClick = (rootStore: RootStore): WidgetEventHandler<any> => {
  const shouldRemoveAddToCartAndCheckoutTrackEvents = rootStore.isExperimentEnabled(
    SPECS.RemoveAddToCartAndCheckoutTrackEvents
  );

  return getCtaButtonClickHandler(rootStore, () => {
    const action = () =>
      rootStore.reportFedopsInteraction(FedopsInteractions.BuyNow, () =>
        createCheckout(rootStore, {withNavigateToCheckout: true})
          .then((checkout) => {
            if (!shouldRemoveAddToCartAndCheckoutTrackEvents) {
              publishInitiateCheckoutTrackEvent(rootStore, checkout.checkoutId as string);
            }
          })
          .then(() => Promise.resolve())
      );

    return interruptAction(rootStore.wixCodeService.onBuyNowInterruption, action);
  });
};
