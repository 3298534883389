import {ExperimentsBag} from '@wix/yoshi-flow-editor';

export const SPECS = {
  PRODUCT_PAGE_BLOCKS_MS4: 'specs.stores.ProductPageBlockMS4',
  PRODUCT_PAGE_BLOCKS_MS3: 'specs.stores.ProductPageBlockMS3',
  UseNewCheckoutInVisitorShippingAndDigitalFlow: 'specs.stores.UseNewCheckoutInVisitorShippingAndDigitalFlow',
  PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE:
    'specs.stores.ProductPageBreadcrumbsLocalizeAllProductsReferringPage',
  AddOptionsToCartWithKeyInsteadOfTranslation: 'specs.stores.AddOptionsToCartWithKeyInsteadOfTranslation',
  PRODUCT_PAGE_BLOCKS_SLOTS: 'specs.stores.ProductPageBlocksSlots',
  PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS: 'specs.stores.ProductPageBlocksRico',
  RenderOnDeviceViewChanged: 'specs.stores.ProductPageBlocksRenderOnDeviceViewChanged',
  PPOBShouldUseProductLevelPricePerUnit: 'specs.stores.PPOBShouldUseProductLevelPricePerUnit',
  NonControlledDropdown: 'specs.stores.ProductPageBlocksNonControlledDropdown',
  FixPricingMismatch: 'specs.stores.ProductPageBlocksFixPricingMismatch',
  ShouldInstallSideCart: 'specs.stores.ShouldInstallSideCart',
  ProductPageBlocksNoNavigationDuringInstallation: 'specs.stores.ProductPageBlocksNoNavigationDuringInstallation',
  ShouldOpenSideCart: 'specs.stores.ShouldOpenSideCart',
  OptimizeSelectionCalculation: 'specs.stores.OptimizeSelectionCalculation',
  RemoveModifiersFromUserInput: 'specs.stores.RemoveModifiersSelectionIdsFromUserSelections',
  UseMiniCartStylesInSideCart: 'specs.stores.UseMiniCartStylesInSideCart',
  PPOBAddDelayedCapturePropToPaymentMethodsBanner: 'specs.stores.PPOBAddDelayedCapturePropToPaymentMethodsBanner',
  EnableStorefrontSubCategories: 'specs.stores.EnableStorefrontSubCategories',
  SendSubcategoriesSeoDataSF: 'specs.stores.SendSubcategoriesSeoDataSF',
  UseFormattedComparePrice: 'specs.stores.ProductPageBlocksUseFormattedComparePrice',
  ShouldCheckDiscountInVariantLevel: 'specs.stores.shouldCheckDiscountVariantLevelPPOB',
  EnableRicoForSeoTags: 'specs.stores.ProductPageBlocksEnableRicoForSeoTags',
  RemoveAddToCartAndCheckoutTrackEvents: 'specs.stores.PPOBRemoveAddToCartAndCheckoutTrackEvents',
  UseStoresCatalogV3PPOB: 'specs.stores.UseStoresCatalogV3PPOB',
  FixNegativeInventoryError: 'specs.stores.ProductPageBlocksFixNegativeInventoryError',
} as const;

const openedExperimentsForTests: string[] = [
  SPECS.PRODUCT_PAGE_BLOCKS_MS3,
  SPECS.PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE,
  SPECS.PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS,
  SPECS.PRODUCT_PAGE_BLOCKS_SLOTS,
  SPECS.AddOptionsToCartWithKeyInsteadOfTranslation,
  SPECS.PPOBShouldUseProductLevelPricePerUnit,
  SPECS.NonControlledDropdown,
  SPECS.FixPricingMismatch,
  SPECS.ShouldInstallSideCart,
  SPECS.ShouldOpenSideCart,
  SPECS.OptimizeSelectionCalculation,
  SPECS.RemoveModifiersFromUserInput,
  SPECS.UseMiniCartStylesInSideCart,
  SPECS.PPOBAddDelayedCapturePropToPaymentMethodsBanner,
  SPECS.SendSubcategoriesSeoDataSF,
  SPECS.EnableStorefrontSubCategories,
  SPECS.ShouldCheckDiscountInVariantLevel,
  SPECS.EnableRicoForSeoTags,
  SPECS.RemoveAddToCartAndCheckoutTrackEvents,
  SPECS.UseStoresCatalogV3PPOB,
  SPECS.FixNegativeInventoryError,
];

const openedExperimentsForSled = [
  'specs.thunderbolt.new_responsive_layout_loggedIn',
  'specs.stores.UseSlugAsWarmupDataKey',
  SPECS.PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE,
  SPECS.PRODUCT_PAGE_BLOCKS_SLOTS,
  SPECS.NonControlledDropdown,
  SPECS.FixPricingMismatch,
  SPECS.ShouldInstallSideCart,
  SPECS.ShouldOpenSideCart,
  SPECS.OptimizeSelectionCalculation,
  SPECS.RemoveModifiersFromUserInput,
  SPECS.UseMiniCartStylesInSideCart,
  SPECS.PPOBAddDelayedCapturePropToPaymentMethodsBanner,
  SPECS.SendSubcategoriesSeoDataSF,
  SPECS.EnableStorefrontSubCategories,
  SPECS.ShouldCheckDiscountInVariantLevel,
  SPECS.EnableRicoForSeoTags,
  SPECS.RemoveAddToCartAndCheckoutTrackEvents,
  SPECS.UseStoresCatalogV3PPOB,
  SPECS.FixNegativeInventoryError,
];

export const getOpenedExperimentsForTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = 'true';
    return prv;
  }, {});
};

export const getOpenedExperimentsForSled = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};

export const mapExperimentsToStrings = (experiments: ExperimentsBag) => {
  return Object.entries(experiments).reduce((acc, [key, value]) => {
    acc[key] = value.toString();
    return acc;
  }, {} as Record<string, string>);
};

export const mapExperimentsToBooleans = (experiments: ExperimentsBag) => {
  return Object.entries(experiments).reduce((acc, [key, value]) => {
    if (value === true || value === false) {
      acc[key] = value;
      return acc;
    } else if (value === 'true' || value === 'false') {
      acc[key] = value === 'true' ? true : false;
      return acc;
    }
    return acc;
  }, {} as Record<string, boolean>);
};
