import { TimezoneType } from '@wix/bookings-uou-types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getEndOfMonthAsLocalDateTime,
  getLocalTimezone,
  getStartOfMonthAsLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { TriggeredByOptions } from '../../../../types/types';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { AddError } from '../addError/addError';
import { InitializeCalendarDateOptions } from '../../../../utils/bi/consts';
import { bookingsCalendarPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { AutoSelectTime } from '../autoSelectTime/autoSelectTime';
import {
  getUrlQueryParamValue,
  BookingsQueryParams as BookingsQueryParamsUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
  autoSelectTime: AutoSelectTime,
): InitializeWidget {
  return async () => {
    const { flowAPI, biLogger, settings, settingsParams, businessInfo } =
      context;
    const {
      environment: { isSSR },
    } = flowAPI;

    if (isSSR) {
      return;
    }

    const [, setState] = getControllerState();

    const selectedTimezone = getSelectedTimezone(context);
    setState({ selectedTimezone });

    const [state] = getControllerState();

    const initialLocalDate = await getInitializeCalendarDate({
      state,
      context,
      addError,
    });

    if (
      isWeeklyTimeSlotsLayout(settings, settingsParams) ||
      isWeeklyTimetableLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      await setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
    } else if (
      isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    } else {
      const startOfMonthAsLocalDateTime =
        getStartOfMonthAsLocalDateTime(initialLocalDate);
      setSelectedMonth(
        startOfMonthAsLocalDateTime,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    }

    autoSelectTime(TriggeredByOptions.INITIALIZE_WIDGET);

    setState({ calendarStatus: CalendarStatus.IDLE });

    biLogger.report(bookingsCalendarPageLoaded({}));
  };
}

function getSelectedTimezone({ businessInfo, flowAPI }: CalendarContext) {
  const localTimezone = getLocalTimezone();
  const preSelectedTimezone = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParamsUtils.TIMEZONE,
  );

  const isPreselectedTimezoneValid = [
    businessInfo!.timeZone,
    localTimezone,
  ].includes(preSelectedTimezone);

  const defaultTimezone =
    businessInfo!.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
      ? localTimezone
      : businessInfo!.timeZone!;

  return isPreselectedTimezoneValid ? preSelectedTimezone : defaultTimezone;
}

const getInitializeCalendarDate = async ({
  state,
  context,
  addError,
}: {
  state: CalendarState;
  context: CalendarContext;
  addError: AddError;
}): Promise<string> => {
  const { selectedDate, selectedTimezone } = state;
  const { settings, settingsParams, calendarApi } = context;
  const isAnonymousCancellationFlow =
    getUrlQueryParamValue(
      context.flowAPI.controllerConfig.wixCodeApi,
      BookingsQueryParamsUtils.REFERRAL,
    ) === 'batel';

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);

  if (isAnonymousCancellationFlow) {
    return selectedDate || todayLocalDateTime;
  }
  if (selectedDate) {
    return selectedDate;
  }

  const shouldInitializeCalendarOnToday =
    settings.get(settingsParams.initializeCalendarDate) ===
    InitializeCalendarDateOptions.TODAY;
  if (shouldInitializeCalendarOnToday) {
    return todayLocalDateTime;
  } else {
    const sixMonthsFromNow = getEndOfMonthAsLocalDateTime(
      todayLocalDateTime,
      7,
    );
    const nextAvailableDate = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: todayLocalDateTime,
        toAsLocalDateTime: sixMonthsFromNow,
      },
      { state, context, onError: addError },
    );
    return nextAvailableDate || todayLocalDateTime;
  }
};
</string></calendarstate,></void>